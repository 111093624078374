import { Button } from '@/components/basic';
import Modal from '@/components/basic/Modal/Modal';
import LoadingSpinner from '@/components/util/LoadingSpinner';
import { useAuthenticated } from '@/lib/api/appUser';
import { classNames } from '@/lib/classNames';
import { isRBFeature } from '@/lib/featureFlags';
import { ExclamationIcon, TrashIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';

type Props = React.PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  type: 'confirm' | 'cancel';
  cancelText?: string;
  confirmText?: string;
  noFlex?: boolean;
  loading?: boolean;
}>;

export function ConfirmDialog({
  open,
  onClose,
  onConfirm,
  title,
  type = 'confirm',
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  loading = false,
  noFlex,
  children
}: Props) {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  return (
    <Modal
      title={
        <span
          className={classNames(
            'flex items-center space-x-2 text-lg',
            type === 'confirm' && 'text-orange-600',
            type === 'cancel' && 'text-red-600'
          )}
        >
          <div className="shrink-0">
            {type === 'confirm' ? (
              <ExclamationIcon
                className="h-6 w-6 text-orange-600"
                aria-hidden="true"
              />
            ) : type === 'cancel' ? (
              <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            ) : (
              <ExclamationIcon
                className="h-6 w-6 text-orange-600"
                aria-hidden="true"
              />
            )}{' '}
          </div>
          <span>{title}</span>
        </span>
      }
      opened={open}
      onClose={onClose}
    >
      <div className={'relative mx-auto max-w-sm rounded-md bg-white'}>
        <div className={noFlex ? undefined : 'flex'}>
          <div>
            {children}
            <Modal.Footer>
              <div className="flex space-x-4 pb-1">
                <Button
                  className="w-full sm:w-auto"
                  layout="squared"
                  variant="transparent"
                  font="normalGray"
                  onClick={onClose}
                >
                  {isRBFeature(authenticatedUser) ? 'Keep' : cancelText}
                </Button>
                <Button
                  className="w-full sm:w-auto"
                  layout="squared"
                  variant="primary"
                  disabled={loading}
                  onClick={() => {
                    onConfirm();
                  }}
                >
                  {confirmText}
                  {loading ? (
                    <>
                      {' '}
                      <LoadingSpinner />
                    </>
                  ) : null}
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </div>
      </div>
    </Modal>
  );
}
