import { Button, Tooltip } from '@/components/basic';
import TimeZoneMismatchAlert from '@/components/partial/TimeZoneMismatchAlert';
import { ReservationRecurringEditConfirm } from '@/components/reserve/ReservationRecurringEditConfirm';
import ReserveFieldDescription from '@/components/reserve/ReserveFieldDescription/ReserveFieldDescription';
import ReserveFieldDuration from '@/components/reserve/ReserveFieldDuration/ReserveFieldDuration';
import ReserveFieldOrganizer from '@/components/reserve/ReserveFieldOrganizer/ReserveFieldOrganizer';
import ReserveFieldParkingList from '@/components/reserve/ReserveFieldParkingList/ReserveFieldParkingList';
import ReserveFieldRecurring from '@/components/reserve/ReserveFieldRecurring/ReserveFieldRecurring';
import ReserveFieldResourcesList from '@/components/reserve/ReserveFieldResourcesList/ReserveFieldResourcesList';
import ReserveFieldStartDate from '@/components/reserve/ReserveFieldStartDate/ReserveFieldStartDate';
import ReserveFieldTime from '@/components/reserve/ReserveFieldTime/ReserveFieldTime';
import ReserveFieldTitle from '@/components/reserve/ReserveFieldTitle/ReserveFieldTitle';
import ReserveFieldUsers from '@/components/reserve/ReserveFieldUsers/ReserveFieldUsers';
import { Form } from '@/components/ui/form';
import FloorFilterDropdown from '@/components/util/FloorFilterDropdown';
import { LoadingSpinner } from '@/components/util/LoadingSpinner';
import { useFloors } from '@/hooks/useAreas';
import { useCalendarByResource } from '@/hooks/useCalendar';
import { useI18n } from '@/hooks/useI18n';
import useReserveResources from '@/hooks/useReserveResources';
import { api } from '@/lib/api/api';
import { useAuthenticated } from '@/lib/api/appUser';
import { classNames } from '@/lib/classNames';
import { showRecurring } from '@/lib/featureFlags';
import { canScheduleOthers } from '@/lib/permissions';
import {
  convertUTCToLocalDate,
  getTimeForReservation,
  primaryScheduleResource
} from '@/lib/utils';
import {
  SaveReservationData,
  editReservations,
  getDurationOptions,
  noParkingResource,
  recurringToRRule,
  saveReservations
} from '@/lib/utils-reserve';
import { isValidEmail } from '@/lib/validation/email';
import { ISchedule, Resource, isParking } from '@gettactic/api';
import { PencilAltIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/solid';
import { Collapse } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { addDays, differenceInMinutes, format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

interface ReserveEditProps {
  schedule: ISchedule;
  onClose: () => void;
  isLoading?: boolean;
  floorId?: null | string;
  canRespondCancel?: boolean;
  respondCancel?: () => void;
}

export const ReserveEdit = ({
  schedule,
  onClose,
  floorId = schedule.resource.parent_id,
  canRespondCancel,
  respondCancel,
  isLoading
}: ReserveEditProps) => {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const { templates } = useI18n(authenticatedUser);

  const [confirmFunction, setConfirmFunction] = useState<
    null | ((x: 'this' | 'future' | 'all') => void)
  >(null);
  const offices = authenticatedUser.offices;
  const queryClient = useQueryClient();
  const [filterFloor, setFilterFloor] = useState<string | null>(floorId);
  const [loading, setLoading] = useState<boolean>(isLoading || false);
  const [notesOpened, setNotesOpened] = useState(false);
  const primaryResource = primaryScheduleResource(schedule.slots);
  const { calendar } = useCalendarByResource(primaryResource.id);
  const entity = primaryResource.type;
  const [error, setError] = useState('');
  const isMeetingRoom = entity === 'meeting_room';
  const isPrimaryParking =
    entity === 'parking_space' || entity === 'parking_lot';
  const parkingSlot = schedule.slots.find((x) => isParking(x.resource));
  const startOfficeDate: Date = convertUTCToLocalDate(schedule.start);
  const endOfficeDate: Date = convertUTCToLocalDate(schedule.end);
  const office = offices?.officeId
    ? offices?.byId[primaryResource.office_id]
    : undefined;
  const { floors } = useFloors(office?.id ?? '');
  const currentOfficeTz = office?.time_zone ?? '';
  const users = schedule.slots
    .filter((x) =>
      isPrimaryParking ? isParking(x.resource) : !isParking(x.resource)
    )
    .map((slot) => {
      return slot.user.id;
    });
  const allowOthers = canScheduleOthers(authenticatedUser);

  const defaultValues = {
    resource_id: primaryResource.id,
    parking_resource_id: parkingSlot ? parkingSlot.resource.id : null,
    start: startOfficeDate,
    time: getTimeForReservation(
      startOfficeDate,
      startOfficeDate,
      currentOfficeTz,
      primaryResource.office_id
    ),
    duration: differenceInMinutes(endOfficeDate, startOfficeDate),
    teams: [],
    users,
    title: schedule.title,
    description: schedule.description
  };

  if (!defaultValues.start || !(defaultValues.start instanceof Date)) {
    defaultValues.start = new Date();
  }
  const [weekDates, setWeekDates] = useState<Date[]>(() => [
    defaultValues.start
  ]);
  const weekDatesStr = weekDates.map((weekDate) =>
    format(weekDate, 'yyyy-MM-dd')
  );
  const [startTime, setStartTime] = useState<Date>(() => defaultValues.time);
  const [durationMin, setDurationMin] = useState<number>(
    () => defaultValues.duration
  );

  const defaultOrganizer = useMemo(() => {
    const organizerSlot = schedule.slots.find(
      (x) => x.user.id === schedule.organizer_id
    );
    return organizerSlot
      ? { value: organizerSlot.user.id, label: organizerSlot.user.name }
      : undefined;
  }, [schedule]);

  const form = useForm<SaveReservationData>({
    defaultValues: {
      title: defaultValues.title ?? '',
      weekDates: [defaultValues.start],
      description: defaultValues.description ?? '',
      organizer: defaultOrganizer
    }
  });

  const currentDuration = form.watch('duration', {
    value: defaultValues.duration
  });
  const durationOptions = useMemo(
    () => getDurationOptions(startTime),
    [startTime]
  );
  const organizer = form.watch('organizer') ?? defaultOrganizer;

  const userIdForResources = isMeetingRoom
    ? ((allowOthers ? organizer?.value : null) ?? null)
    : users[0];
  const { resourcesList: resources, parkingResourcesList: parkings } =
    useReserveResources({
      resourceType: entity,
      officeId: primaryResource.office_id,
      durationMins: durationMin,
      startTime,
      weekDatesStr,
      organizer: isValidEmail(userIdForResources) ? null : userIdForResources
    });

  useEffect(() => {
    const exists = durationOptions.find(
      (x) => x.value === currentDuration.value
    );
    if (!exists) {
      const tryDefault = durationOptions.find(
        (x) => x.value === defaultValues.duration
      );
      form.setValue(
        'duration',
        tryDefault ?? durationOptions[durationOptions.length - 1]
      );
    }
  }, [currentDuration, durationOptions]);

  /**
   * We should add the current resource
   * because will not available due that is
   * already reserved.
   */
  const resourcesList = useMemo(() => {
    const defaultResource = { ...primaryResource, label: primaryResource.name };
    if (!resources) {
      return [defaultResource as Resource];
    }
    const has = resources.find((x) => x.id === primaryResource.id);
    if (has) {
      return resources;
    }
    return resources.concat([defaultResource]);
  }, [resources, schedule]);

  const parkingResourcesList = useMemo(() => {
    const defaultParking = parkingSlot?.resource;
    if (!defaultParking) {
      return parkings;
    }
    if (!parkings) {
      return [defaultParking];
    }
    const has = parkings.find((x) => x.id === defaultParking.id);
    if (has) {
      return parkings;
    }
    return parkings.concat([defaultParking]);
  }, [parkings, parkingSlot]);

  const defaultResource =
    defaultValues.resource_id && resourcesList
      ? (resourcesList.filter(
          (resource) => resource.id === defaultValues.resource_id
        )[0] ?? null)
      : null;
  const defaultParking = useMemo(() => {
    return (
      parkingResourcesList?.find(
        (x) => x.id === defaultValues.parking_resource_id
      ) ?? noParkingResource
    );
  }, [parkingResourcesList, defaultValues, noParkingResource]);

  const originalValues = {
    ...defaultValues,
    duration: {
      value: defaultValues.duration,
    },
    users: users.map(u => { return { value: u } }),
    weekDates: [defaultValues.start],
    organizer: defaultOrganizer,
    resource: defaultResource,
    parking_resource: defaultParking,
    recurring: recurringToRRule(schedule).toString(),
  } as SaveReservationData;

  const onSubmit: SubmitHandler<SaveReservationData> = async (data, ev) => {
    ev?.stopPropagation();
    ev?.preventDefault();
    const callback = async (value: 'this' | 'future' | 'all') => {
      setLoading(true);
      const recurringSchedule =
        schedule.recurring_schedule_id && (value === 'future' || value === 'all')
          ? await api.client.schedules.schedulesById(
              [schedule.recurring_schedule_id],
              primaryResource.office_id,
              {
                schedule_types: ['recurring'],
                slot_types: ['recurring']
              }
            )
          : null;

      const scheduleToEdit = recurringSchedule?.result?.elements[0]
        ? recurringSchedule?.result?.elements[0]
        : schedule;

      // If we are editing a recurring schedule, there is a chance that the instance differs from the reccurring template
      // In this case, we need to check the delta between the three data sets. The easiest way to do this
      // is by filtering out the existing schedule instance user's from the form data and then combining the filtered
      // data with the recurring template users. This will give us the delta between the instance and the template.
      if (
        value === 'future' &&
        recurringSchedule &&
        recurringSchedule?.result?.elements[0]
      ) {
        const recurringTemplateUsers =
          recurringSchedule.result.elements[0].slots.map((x) => ({
            value: x.user.id
          }));
        const instanceUsers = schedule.slots.map((x) => ({ value: x.user.id }));
        const differingUsers = instanceUsers.filter(
          (userObj) =>
            !recurringTemplateUsers.map((x) => x.value).includes(userObj.value)
        );
        data.users = data.users.filter(
          (userObj) =>
            !differingUsers.map((x) => x.value).includes(userObj.value)
        );
      }

      const isFirstOccurrence = format(originalValues.time, 'yyyy-MM-dd') === scheduleToEdit.recurring_task?.start?.split('T')[0];
      let hasError = false;
      let message: string | undefined;

      // Edit the current schedule and all the future ones, to do that we split
      // the schedule into two parts, changing the current one end time and creating
      // a new one for the future. Do this only if the schedule being edited is not
      // the first occurrence, in that case we just edit everything, there's no need
      // to create a new schedule
      if (value === 'future' && !isFirstOccurrence) {
        const newSchedule = { ...data, organizer };

        // Edit the current schedule with the new end time
        const newEnd = addDays(newSchedule.time, -1);
        scheduleToEdit.recurring_task!.end = `${format(newEnd, 'yyyy-MM-dd')}T23:59:59.000Z`;

        const editResult = await editReservations(
          scheduleToEdit,
          {
            ...originalValues,
            recurring: recurringToRRule(scheduleToEdit).toString(),
            organizer
          },
          true,
          templates.displayDate
        );
        if (editResult.hasError && editResult.message) {
          setLoading(false);
          setError(editResult.message);
          return
        }

        // Create a new schedule starting from the current schedule being edited
        // and to the future using the new values
        const saveResult = await saveReservations(newSchedule, {
          organizerId: newSchedule.organizer.value,
          isMeetingRoom: isMeetingRoom,
          authUserId: authenticatedUser.user!.id,
          office: office,
          allowOthers: allowOthers,
          timeZone: office?.time_zone || '',
          officeId: office?.id || '',
          noParkingResource: noParkingResource,
          dateFormat: templates.displayDate
        })
        hasError = saveResult.hasError;
        message = saveResult.message;
      } else if (value === 'all' || (value === 'future' && isFirstOccurrence)) {
        const result = await editReservations(
          scheduleToEdit,
          { ...data, organizer },
          true,
          templates.displayDate
        );
        hasError = result.hasError;
        message = result.message;
      } else {
        const result = await editReservations(
          scheduleToEdit,
          { ...data, organizer },
          false,
          templates.displayDate
        );
        hasError = result.hasError;
        message = result.message;
      }

      setLoading(false);
      if (hasError && message) {
        setError(message);
      } else {
        await queryClient.invalidateQueries(['schedules']);
        await queryClient.invalidateQueries(['organizations.schedulable']);
        onClose();
      }
    };

    // If we are editing a recurring schedule, we need to confirm if the user wants to
    // edit this instance only or all future instances. This mimics Google Calendar.
    if (schedule.recurring_schedule_id) {
      setConfirmFunction(() => callback);
    } else {
      await callback('this');
    }
  };

  const enableFloorSelector = (floors?.length ?? 0) > 1;

  return (
    <div className="text-left">
      {office ? (
        <TimeZoneMismatchAlert
          onClose={onClose}
          className="mb-5 max-w-xl"
          timezone={currentOfficeTz}
          officeId={office.id}
        />
      ) : null}
      <Form {...form}>
        <form className="mt-2 flex" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-1 flex-col">
            {isMeetingRoom ? (
              <div className="mb-2">
                <ReserveFieldTitle form={form} />
              </div>
            ) : null}

            <div
              className={classNames(
                'mb-2',
                enableFloorSelector || isMeetingRoom
                  ? 'grid grid-cols-3 gap-3'
                  : null
              )}
            >
              <div>
                {office ? (
                  <ReserveFieldStartDate
                    form={form}
                    defaultValue={weekDates}
                    startTime={startTime}
                    weekDates={weekDates}
                    officeTimeZone={currentOfficeTz}
                    errors={form.formState.errors}
                    resType={entity}
                    office={office}
                  />
                ) : null}
              </div>
              <div>
                <ReserveFieldTime
                  multipleDays={false}
                  form={form}
                  defaultValue={defaultValues.time}
                  officeId={office?.id}
                  officeTimeZone={currentOfficeTz}
                  errors={form.formState.errors}
                  setStartTime={setStartTime}
                  weekDates={weekDates}
                />
              </div>
              <div className="text-left">
                <ReserveFieldDuration
                  startTime={startTime}
                  durationOptions={durationOptions}
                  form={form}
                  defaultValue={defaultValues.duration}
                  setDurationMin={setDurationMin}
                  errors={form.formState.errors}
                />
              </div>
            </div>
            <div className="text-left">
              {showRecurring(entity, authenticatedUser, office!) ? (
                <ReserveFieldRecurring
                  errors={form.formState.errors}
                  defaultValue={recurringToRRule(schedule).toString()}
                  form={form}
                />
              ) : null}
            </div>

            {isMeetingRoom ? (
              <div className="mt-2 text-left">
                <ReserveFieldUsers
                  form={form}
                  defaultValue={defaultValues.users}
                  filterValues={(option) =>
                    option.value !== authenticatedUser?.user?.id
                  }
                  errors={form.formState.errors}
                />
              </div>
            ) : null}

            {enableFloorSelector ? (
              <div className="mt-2">
                <FloorFilterDropdown
                  floors={floors ?? []}
                  floor={filterFloor}
                  setFloor={setFilterFloor}
                  readonly={false}
                />
              </div>
            ) : null}

            <div className="relative mt-3 text-left">
              {defaultResource ? (
                <ReserveFieldResourcesList
                  form={form}
                  errors={form.formState.errors}
                  resourceType={entity}
                  officeId={primaryResource.office_id}
                  resourcesList={resourcesList}
                  defaultValue={defaultResource}
                />
              ) : null}
            </div>
            <div className="mt-3">
              {!isMeetingRoom &&
              !isPrimaryParking &&
              office &&
              office.total_parking_spaces > 0 ? (
                <div className="mb-5">
                  <ReserveFieldParkingList
                    noParkingResource={noParkingResource}
                    parkingResourcesList={parkingResourcesList}
                    defaultValue={defaultParking}
                    form={form}
                    errors={form.formState.errors}
                  />
                </div>
              ) : null}
            </div>

            {isMeetingRoom ? (
              <div className="mt-1 mb-2">
                <button
                  className="flex items-center pb-2 text-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setNotesOpened((s) => !s);
                  }}
                >
                  <PencilAltIcon className="mr-1.5 h-4 w-4" />
                  Meeting notes
                </button>
                <Collapse in={notesOpened}>
                  <ReserveFieldDescription form={form} />
                </Collapse>
              </div>
            ) : null}

            {!isMeetingRoom ? (
              <div className={classNames('mt-2', !allowOthers && 'hidden')}>
                {allowOthers ? (
                  <div className="flex items-center">
                    <span className="font-medium text-gray-700">User</span>
                    <Tooltip position="top">
                      Make this reservation on behalf of another user
                    </Tooltip>
                  </div>
                ) : null}
                <ReserveFieldUsers
                  single={true}
                  form={form}
                  defaultValue={users}
                  errors={form.formState.errors}
                  readonly={!allowOthers}
                />
              </div>
            ) : (
              <div className={classNames('mt-2', !allowOthers && 'hidden')}>
                {allowOthers ? (
                  <div className="flex items-center">
                    <span className="font-medium text-gray-700">Organizer</span>
                    <Tooltip position="top">
                      Select an organizer for the meeting. As you are creating
                      this reservation on behalf of another user, you will not
                      be the organizer, nor one of the invitees.
                    </Tooltip>
                  </div>
                ) : null}
                <ReserveFieldOrganizer
                  form={form}
                  defaultValue={
                    organizer
                      ? organizer
                      : users
                        ? { value: users[0] }
                        : undefined
                  }
                  errors={form.formState.errors}
                  readonly={!allowOthers}
                />
              </div>
            )}

            <div className="pb-4 text-sm text-red-600">{error}</div>

            <Button
              type="submit"
              className="w-full"
              variant="primary"
              layout="rounded"
              font="boldPrimary"
              disabled={loading}
            >
              {loading ? (
                <LoadingSpinner color="text-white" />
              ) : (
                <span>Save Changes</span>
              )}
            </Button>
            {canRespondCancel && (
              <Button
                type="button"
                className={`w-full bg-transparent hover:bg-transparent
                active:bg-transparent justify-center
                shadow-none focus:shadow-none mt-4
                `}
                variant="transparent"
                severity="critical"
                layout="block"
                font="boldPrimary"
                disabled={loading}
                iconLeft={TrashIcon}
                onClick={respondCancel}
              >
                {loading ? (
                  <LoadingSpinner color="text-white" />
                ) : (
                  <span>Cancel Reservation</span>
                )}
              </Button>
            )}
          </div>
        </form>
      </Form>
      {confirmFunction ? (
        <ReservationRecurringEditConfirm
          open={!!confirmFunction}
          onClose={() => {
            setConfirmFunction(null);
          }}
          confirmFunction={confirmFunction}
          // If the room has a calendar integration, we can only edit single instances
          singleInstanceOnly={!!calendar?.integration_id}
        />
      ) : null}
    </div>
  );
};

export default ReserveEdit;
