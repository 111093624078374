import { api } from "@/lib/api/api";
import { ISchedule } from "@gettactic/api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export const useOneSchedule = ({
  id,
  options,
  defaultSchedule,
}: {
  id: string;
  options: UseQueryOptions<ISchedule>;
  defaultSchedule: ISchedule;
}) => {
  const res = useQuery<ISchedule>(
    ["schedules", id],
    async () => {
      const result = await api.client.schedules.all("managed", {
        schedule: [id],
      });
      if (result.ok && result.result?.elements?.[0]) {
        return result.result.elements[0];
      }
      return defaultSchedule;
    },
    options
  );

  return res;
};
