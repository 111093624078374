import React, { useCallback, useMemo, useState } from 'react';
import { Input, Label } from '@/components/basic';
import { ConfirmDialog } from '../basic/ConfirmDialog/ConfirmDialog';
import { Alert } from '@/components/basic';

type RecurringOptions = 'this' | 'future' | 'all';

export interface Props {
  open: boolean;
  onClose: () => void;
  confirmFunction: (recurring: RecurringOptions) => void;
  singleInstanceOnly?: boolean;
}

export function ReservationRecurringEditConfirm({
  onClose,
  open,
  confirmFunction,
  singleInstanceOnly
}: Props) {
  const [recurringValue, setRecurringValue] =
    useState<RecurringOptions>('this');
  const onCloseBind = useCallback(() => {
    setRecurringValue('this');
    onClose();
  }, [setRecurringValue, onClose]);

  const options: { value: RecurringOptions; label: string, disabled: boolean }[] = useMemo(
    () => [
      { label: 'Only this reservation', value: 'this', disabled: false },
      { label: 'This and all the future reservations', value: 'future', disabled: !!singleInstanceOnly },
      { label: 'All the reservations', value: 'all', disabled: !!singleInstanceOnly }
    ], []
  );

  return (
    <ConfirmDialog
      title={'Edit Recurring Reservation'}
      open={open}
      onConfirm={() => {
        confirmFunction(recurringValue);
      }}
      onClose={onCloseBind}
      type="confirm"
    >
      <div className="mt-2 flex flex-col space-y-4 text-sm">
        {options.map((option) => (
          <Label key={option.value} radio className="block" disabled={option.disabled}>
            <Input
              type="radio"
              onChange={() => {
                setRecurringValue(option.value);
              }}
              checked={option.value === recurringValue}
              disabled={option.disabled}
            />
            <span className="ml-2">{option.label}</span>
          </Label>
        ))}
      </div>

      {
        singleInstanceOnly ?
        <Alert
          variant="tacticAlert"
          canDismiss={false}
          alertTitle="System Maintenance"
          alertMessage=""
          className="mt-4 px-4 py-2"
        >
          <p className="text-sm">
            Recurring room reservations are under maintenance. Please edit them directly in Google or Outlook for now.
          </p>
        </Alert> : null
      }
    </ConfirmDialog>
  );
}
