import { format, isToday } from 'date-fns';
import { classNames } from '@/lib/classNames';
import { dateParseISO } from '@/lib/utils';

type Props = {
  time: string | null;
  type: 'checkin' | 'checkout';
  clsNames?: {
    general?: string;
    checkout?: string;
    checkin?: string;
    time?: string;
  };
};

export function ReserveCheckTime({
  time,
  type,
  clsNames = {}
}: Props): JSX.Element {
  clsNames = {
    ...{
      general: 'mr-2',
      checkout: '',
      checkin: '',
      time: ''
    },
    ...clsNames
  };
  if (!time) {
    return (
      <span className={classNames(clsNames.general, clsNames[type])}>
        {type === 'checkout' ? 'Checked Out' : 'Checked in'}
      </span>
    );
  }
  const date = dateParseISO(time);
  const formatTime = format(
    date,
    isToday(date) ? 'hh:mm aa' : 'hh:mm aa, MMM d'
  );
  return (
    <span
      title={type === 'checkin' ? 'Checked in time' : 'Checked out time'}
      className={classNames(clsNames.general, clsNames[type])}
    >
      {type === 'checkin' ? 'Checked in at' : 'Checked Out'}{' '}
      <span className={clsNames.time}>{formatTime}</span>
    </span>
  );
}
